@import url(https://fonts.googleapis.com/css?family=Open+Sans);
@import url(https://fonts.googleapis.com/css?family=Lato);
.FAQ h2 {
  margin-block: 0.5em !important;
  text-decoration: underline;
}

.FAQ p {
  margin-top: 0.1em;
}

.contactInfoBlurred {
  filter: blur(0.5em);
  cursor: pointer;
}

.FAQTextButton {
  color: var(--rebaneGreen);
  cursor: pointer;
  text-decoration: underline;
}

.languages {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.languages img {
  cursor: pointer;
  height: 50px;
}

.flag_et_pop {
  display: none;
}

.flag_et:hover {
  display: none;
}

.flag_et:hover + .flag_et_pop {
  display: initial;
}

.imgLoadingGif {
  position: absolute;
}

.tooltipContainer {
  display: inline-block;
}

.tooltipText {
  position: absolute;
  z-index: 100;
  margin: 0;
  padding: 0 0.3em 0.1em 0.3em;
  font-family: "kenpixel_mini_square";
  background: var(--rebaneGreen);
  color: white;
  font-weight: normal !important;
}


.blockImage {
  width: 32px;
  height: 32px;
  margin-top: 3px;
  margin-bottom: 3px;
  margin-right: 3px;
  margin-left: 3px;
  background-color: #222222;
  transition: background-color 0.2s;
}

.colourSet {
  display: flex;
  flex-direction: row;
}

.colourSetBox {
  width: 24px;
  height: 24px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  margin-top: 6px;
  margin-right: 3px;
  margin-left: 3px;
  flex-shrink: 0;
}

.colourSetBlocks {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.greenButton {
  display: block;
  font-family: "kenpixel_mini_square";
  color: #333333;
  cursor: pointer;
  position: relative;
  width: 300px;
  margin-top: 0.1em;
  margin-bottom: 0.1em;
}

.greenButton_text {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  text-align: center;

  line-height: 29px;
  z-index: 10;
}

.greenButton_text_dummy {
  position: unset;
  visibility: hidden;
  width: 100%;
  text-align: center;
  display: block;

  line-height: 29px;
  z-index: 10;
}

.greenButton_large_text {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  text-align: center;

  line-height: 50px;
  z-index: 10;
  font-size: 26px ;
}

.greenButton_large_text_dummy {
  position: unset;
  visibility: hidden;
  width: 100%;
  text-align: center;
  display: block;

  line-height: 50px;
  z-index: 10;
  font-size: 26px ;
}

.greenButton_progressDiv {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #688e6b;
  height: 100%;
}

.imgUpload {
  display: none;
}

.gridOverlay {
  position: absolute;
  background-repeat: repeat;
  image-rendering: optimizeSpeed;
  image-rendering: -o-crisp-edges;
  image-rendering: pixelated;
  -ms-interpolation-mode: nearest-neighbor;
  pointer-events: none;
}

.mapCanvas {
  image-rendering: optimizeSpeed; /* Older versions of FF */ /* FF 6.0+ */ /* Safari */
  image-rendering: -o-crisp-edges; /* OS X & Windows Opera (12.02+) */
  image-rendering: pixelated; /* Awesome future-browsers */
  -ms-interpolation-mode: nearest-neighbor; /* IE */
  cursor: pointer;
  font-smooth: never;
  -webkit-font-smoothing: none;
  subpixel-antialias: none;
}

.mapResolutionAndZoom {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.sizeButton {
  margin-top: 2px;
  margin-left: 2px;
  width: 15px;
  height: 15px;
  cursor: pointer;
}

.progress {
  background-color: black;
}

.progressText {
  position: absolute;
  padding-left: 6px;
}

.progressDiv {
  background-color: #688e6b;
  height: 24px;
}

.blockToAddSuggestions {
  position: absolute;
  z-index: 50;
  margin: 0;
  padding: 0;
  font-family: "kenpixel_mini_square";
  background: var(--rebaneGreen);
  color: white;
}

.blockToAddSuggestion th {
  font-weight: normal;
  padding: 0.2em;
}

.blockToAddSuggestion th:first-child {
  display: flex;
  justify-content: center;
  align-items: center;
}

.blockToAddSuggestion:hover {
  background-color: #609060;
  cursor: pointer;
}


.settingsGroup {
  margin-block-start: 0.2em;
  margin-block-end: 0.2em;
  padding: 0.2em;
  outline: 0.1em solid white;
}

.chunkCoordsInput {
  width: 2em;  
  padding: 1px;
}
 
.viewOnline_waila {
  font-family: "kenpixel_mini_square";
  background: var(--rebaneGreen);
  color: white;
  padding: 0.3em;
}

.viewOnline_waila h3 {
  margin: 0;
  font-weight: normal;
}

.viewOnline2DContainer {
  position: fixed;
  z-index: 120;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: #7bae7f5e;
}

.viewOnline2DContainer .topBar {
  position: relative;
  z-index: 122;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: var(--rebaneGreen);
}

.viewOnline2DContainer .topBar * {
  z-index: 122;
}

.viewOnline2DContainer .chunkCoordsInput {
  width: 2em;
  padding: 1px;
}

.viewOnline2DContainer canvas {
  z-index: 121;
  image-rendering: optimizeSpeed; /* Older versions of FF */ /* FF 6.0+ */ /* Safari */
  image-rendering: -o-crisp-edges; /* OS X & Windows Opera (12.02+) */
  image-rendering: pixelated; /* Awesome future-browsers */
  -ms-interpolation-mode: nearest-neighbor; /* IE */
}

.mapartController,
.sectionsPreviewSettingsMaterials {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
}

.sectionsPreviewSettingsMaterials {
  flex: 1 1;
}

.section {
  padding: 0.3em;
}

.titleAndLanguages {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
}

.fixedMessages {
    position: fixed;
    bottom: 0.1em;
    right: 0.1em;
    max-width: 20em;
    z-index: 200;
}

.fixedMessage {
    margin: 0.1em;
    padding: 0.3em;
    border: 0.1em solid var(--rebaneGreen);
    background-color: #333;
}

.fixedMessage p {
    margin: 0;
}

.fixedMessage button {
    width: 100%;
}

:root {
  --rebaneGreen: #7BAE7F;
}

@font-face {
  font-family: "kenpixel_mini_square";
  src: url(/static/media/kenpixel_mini_square.049f97fd.woff) format("woff");
}

html {
  background-color: #333;
}

h1,
h2 {
  margin: 0;
}

body {
  font-family: Lato, Open Sans, Arial;
  color: #fff;
  font-size: 16px;
  margin: auto auto;
  padding: 1em;
  line-height: 1.4;
  text-align: justify;
}

a,
a:visited {
  color: var(--rebaneGreen);
}

[type="radio"] {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}

table {
  border-collapse: collapse;
}

th:first-child {
  /* For tables in map settings to align with other settings */
  padding-left: 0;
}

.displayNone {
  display: none;
}

